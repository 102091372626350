<template>
  <Dialog style="width: 30%" header="添加专家" v-model:visible="show">
    <div style="margin: 0 10%">
      <div class="p-fluid p-mb-5">
        <label>姓 名：</label>
        <InputText @blur="change" v-model="form.userName" type="text"></InputText>
        <span class="change">{{ mas }}</span>
      </div>
      <div class="p-fluid p-mb-5">
        <label>电话号码：</label>
        <InputText @blur="change1" v-model="form.phone" type="text"></InputText>
        <span class="change">{{ mas1 }}</span>
      </div>
      <div style="display:flex;justify-content: center">
        <Button @click="submit" label="确认并发送短信" class="p-mr-2" icon="pi pi-fw pi-send"/>
      </div>
    </div>
    <Toast/>
  </Dialog>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      show: false,
      mas: null,
      mas1: null,
      form: {
        userName: null,
        phone: null
      },
      items: [
        {label: '智库推送', to: '/expertManagement'},
        {label: '专家管理', to: '/expertManagement'},
        {label: '添加专家', to: '/addExperts'},
      ]
    }
  },
  methods: {
    init(){
      this.show=true
    },
    change(e) {
      let b = e.target.value.replace(/[^\u4E00-\u9FA5]/g, '')
      if (b != e.target.value) {
        this.mas = "您输入格式有误，请重新输入"
      } else {
        if (b.length > 5) {
          this.mas = "请正确的输入您的姓名"
        } else {
          this.mas = null;
        }
      }
    },
    change1(e) {
      let b = e.target.value.replace(/[^\d]/g, '')
      if (b != e.target.value) {
        this.mas1 = "您输入格式有误，请重新输入"
      } else {
        if (b.length != 11) {
          this.mas1 = "请正确的输入您的手机号"
        } else {
          this.mas1 = null;
        }
      }
    },
    submit() {
      (this.form.userName == null | this.form.userName == "") ? this.mas = "必填项，请输入" : this.mas = null;
      (this.form.phone == null | this.form.phone == "") ? this.mas1 = "必填项，请输入" : this.mas1 = null;
      if (this.form.userName != null & this.form.userName != "" & this.form.phone != null & this.form.phone != "") {
        this.$http.post('/login/addPublicUser1', this.form).then((res) => {
          if (res.data) {
            this.$toast.add({severity: 'success', summary: '成功', life: 3000});
          } else {
            this.$toast.add({severity: 'info', summary: '专家已存在,请检查输入', life: 3000});
          }
          this.$emit('close',true)
        })
      }
    }
  }
}
</script>

<style scoped>
.change {
  color: red;
}

</style>